<template>
  <page name="Ошибки в логах контента" :selectSource="false">
    <template v-slot:toolbar>
      <filter-component
        v-model:filter="filterModel"
        @filterData="onclickFilterChange"
        :selectInterval="false"
        :methods="false"
        :logsLevel="false"
      ></filter-component>
    </template>
    <template v-slot="slotProps">
      <content-logs-view-new :filter="filterModelView"></content-logs-view-new>
      <bs-row v-if="!filterModelView">
        <bs-col>
          <div class="set-filter">Нажмите кнопку отфильтровать!</div>
        </bs-col>
      </bs-row>
    </template>
  </page>
</template>

<script lang="ts">
import { Options, Vue } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ContentLogsViewNew from "./components/content-logs-view-new.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import EnumLogLevel from "@/dataSource/api/Tirscript/LoggerMicroService/Enums/EnumLogLevel";

@Options<ContentLogsViewErrorsPage>({
  components: {
    Page,
    LogsViewFilter,
    ContentLogsViewNew,
  },
})
export default class ContentLogsViewErrorsPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;
  getUtc = getUtc;

  onclickFilterChange() {
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.LogLevel = EnumLogLevel.Error;
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
</script>

<style lang="less">
.other-logs-view-page {
  .min-width {
    min-width: 150px;
  }
}
</style>